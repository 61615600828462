import React from 'react'
import './header.css'
function Header() {
  return (
    <div className='header'>
        <div className="header-content">
            <div className="header-left">
                <h3>S.S</h3>
            </div>
            <div className="header-right">
                <div className="header-right-content">
                    <ul>
                        <li><a href="#intro">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#skills">Skills</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#contact">Contact</a></li>

                    </ul>
                </div>    
            </div>        
        </div>    
     </div>   
  )
}

export default Header