import React,{useState} from 'react'
import './contact.css'
import img from "../image/contact.png"
import emailjs from '@emailjs/browser';

function Contact({darkMode}) {
    const [username,setusername] = useState('');
    const [email,setemail] = useState('');
    const [message,setmessage] = useState('');
    const [isUsernameValid,setisUsernameValid] = useState(false);
    const [isEmailValid,setisEmailValid] = useState(false);
  return (
    <div className="contact" id="contact">
        <div className="contact-content"
            style={{
                color: darkMode?'white':'black',
            }}
        >
            <h1> CONTACT ME</h1>
            <div className="contact-form mt-5">
                <div className="contact-left">
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        if(isEmailValid && isUsernameValid && message.length>0){
                            document.getElementById("send-message-btn").disabled = true;
                            document.getElementById("send-message-btn").cursor = "not-allowed";
                            let templateParams = {
                                username: username,
                                email: email,
                                message: message
                            };
                            emailjs.send('service_aan553g', 'template_5yqcswm', templateParams, 'bvVP9wHLcWYDj3mCG')
                            .then((result) => {
                                document.getElementById("send-message-btn").disabled = false;
                                document.getElementById("send-message-btn").cursor = "allowed";
                                e.target.reset();
                                document.getElementById("success-contact").style.display = "block";
                                setTimeout(()=>{
                                    document.getElementById("success-contact").style.display = "none";
                                }
                                ,5000);
                            }, (error) => {
                                document.getElementById("send-message-btn").disabled = false;
                                document.getElementById("send-message-btn").cursor = "allowed";
                            });
                           
                        }
                        else{
                            document.getElementById("fail-to-validate").style.display = "block";
                            setTimeout(()=>{
                                document.getElementById("fail-to-validate").style.display = "none";
                            }
                            ,5000);
                        }
                        
                    }}>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" placeholder="Enter your name"
                            style={{
                                backgroundColor: darkMode?'white':'#B7AAF1'
                            }}                            
                            onChange={(e)=>{
                                setusername(e.target.value);
                                if (e.target.value.length>0){
                                    e.target.style.borderBottom = "4px solid green";  
                                    setisUsernameValid(true);                                   
                                }
                                else{
                                    e.target.style.borderBottom = "4px solid red";
                                }
                            }}/>
                            <input type="email" className="form-control" id="email" placeholder="Enter your email"
                            style={{
                                backgroundColor: darkMode?'white':'#B7AAF1'
                            }}   
                            onChange={(e)=>{
                                setemail(e.target.value);
                                let regex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                                if (e.target.value.match(regex)){
                                    e.target.style.borderBottom = "4px solid green";  
                                     
                                    setisEmailValid(true);                                      
                                }
                                else{
                                    e.target.style.borderBottom = "4px solid red";
                                }
                            }}/>
                            <textarea className="form-control" id="message" rows="5" placeholder="Enter your message"
                            style={{
                                resize:"none",
                                overflow:"hidden",
                                backgroundColor: darkMode?'white':'#B8D7F2'
                            }}
                            onChange={(e)=>{
                                setmessage(e.target.value);
                                if (e.target.value.length>0){
                                    e.target.style.borderBottom = "4px solid green";   
                                                               
                                }
                                else{
                                    e.target.style.borderBottom = "4px solid red";
                                }
                            }}></textarea>
                            <div id="fail-to-validate" style={{display:"none"}}>
                            <h5 style={{color:"red"}}>❗Please fill all the required field</h5>
                        </div>
                            <button type="submit" className="btn btn-primary" id="send-message-btn"><i className="fa fa-paper-plane"></i></button>
                        </div>
                    </form>
                </div>
                <div className="contact-right">
                    <img src={img} alt="contact" />
                </div>
                <div id="success-contact" style={{display:"none"}}>
                    <h5 style={{color:"green"}}>Thank you ❤️ for contacting me</h5>
                </div>
                
                    
            </div> 
            <div className="contact-footer" style={{
                    color: darkMode ? "black" : "white"
                }}>
                <h1 className="contact-footer-heading mt-3"
                style={{
                    color: darkMode ? "white" : "black"
                }}
                
                >FIND ME ON</h1>
                <div className="contact-footer social-networks"
                    style={{
                        color: darkMode ? "black" : "white",
                        padding: "10px"
                    }}
                >
                    <a href="https://www.facebook.com/thepprincce" target="_blank" rel="noreferrer"><i className="fa fa-2x fa-facebook"></i></a>
                    <a href="https://www.instagram.com/me_sudip_shrestha/" target="_blank" rel="noreferrer"><i className="fa fa-2x fa-instagram"></i></a>
                    <a href="https://www.linkedin.com/in/sudip-shrestha-89739b1b6/" target="_blank" rel="noreferrer"><i className="fa fa-2x fa-linkedin"></i></a>
                    <a href="https://twitter.com/Stha_sud" target="_blank" rel="noreferrer"><i className="fa fa-2x fa-twitter"></i></a>
                </div>

            </div>
                
               
        </div>    
    </div>    
  )
}

export default Contact