import React from 'react'
import './intro.css'
import me from "../image/myself.png"
import LightModeIcon from '@mui/icons-material/LightMode';
import WavingHandIcon from '@mui/icons-material/WavingHand';
function Intro({darMode, toggle}) {
  return (
    <div className='intro'>
        <button  onClick={(e)=>{
            toggle();
        }
        } className={darMode?'btn btn-light':'btn btn-dark'
        }
        style={{
            position:'sticky',
            top:'10px',
            zIndex:'1000',
            left:'10px',

        }}
        
        ><LightModeIcon/></button>

        <div className="intro-content">
            <div className="intro-left" style={{
                    color: darMode?'white':'black'
                }}>
                 <h1 >
                <WavingHandIcon 
                className='waving-hand'
                sx={{
                    fontSize: 40,
                    paddingRight:'10px',
                }}
                />
                {/* <i className="fa fa-thin fa-hand"
                style={{
                    color: darMode?'white':'black'
                }}
                ></i>  */}
                 I'm <span className='name'>Sudip</span></h1>
                 <div className="intro-sub" style={{
                    color: darMode?'white':'black',
                    width:'60%',
                 }}>
                    <p style={{
                        fontFamily:'monospace',
                    }}>I am Computer Engineer by Profession. Currently, I am working as Data Engineer. I am passionate about Data Science, Machine Learning, Deep Learning, Artificial Intelligence, and Web Development.</p>
                </div>
                <h2 className='profession'>Data Engineer<sup class="power" style={{
                    color: darMode?'white':'red',
                    verticalAlign:'super',
                    fontSize: 'small',
                    
                }}>Profession</sup> </h2>
                <div className="intro-button">
                        <span style={{
                            fontFamily:'monospace',
                            marginRight:'10px',
                        }}>Let's Work Together</span>
                        <button className='btn btn-primary'onClick={(e)=>{
                            e.preventDefault();
                            window.location.href='#contact';
                        }}>Hire Me</button>
                    
                    
                        <span style={{
                            fontFamily:'monospace',
                            marginRight:'10px',
                            width:'60%',
                        }}>Share and explore ideas on KP - Knowledge Portals. Post and discover valuable content on various topics, connecting with a global community</span>
                        <button className='btn btn-info'onClick={(e)=>{
                            e.preventDefault();
                            window.open('https://blog-sudips413.vercel.app/');
                        }}>Visit Blog</button>
                    </div>
                
                {/* <div className="intro-contribution">
                    <span>Want to contribute to my project?</span>
                    <button className='btn btn-success' onClick={(e)=>{
                        e.preventDefault();
                        window.open('https://server-mav2.onrender.com/')}
                    }>Contribute</button>
                </div>     */}
            </div>
            <div className="intro-right">
                <img src={me} alt="me"
                // style={{
                //     // maskImage:darMode?'linear-gradient(to bottom, black,transparent);':'none'
                //     mask: darMode?'linear-gradient(to bottom, black,transparent);':'none'
                // }}                
                />                
            </div>    
        </div>    
    
    </div>    
  )
}

export default Intro