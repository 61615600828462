
import './App.css';
import Intro from './components/Introduction/Intro';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/Header/Header';
import { useState } from 'react';
import Contact from './components/Contact/Contact';

function App() {
  const [darMode, setDarkMode] = useState(true);
  const toggle = ()=>{
    setDarkMode(!darMode);
  }
  console.log(darMode);


  return (
    <div className="App" style={{
      backgroundColor: darMode?'#000':'#F1F1F1',
    }}>
      {/* <Header/> */}
      <Intro darMode={darMode} toggle={ toggle }/>
      <Contact darkMode={darMode}/>
    </div>  
  );
}

export default App;
